
	<div class="container h-100 center-block" style="margin: auto;">
		<div class="d-flex justify-content-center h-100">
			<div class="user_card">
				<div class="d-flex justify-content-center">
					<div class="brand_logo_container">
						<img src="../../assets/img/logo.png" class="brand_logo" alt="Logo" width="220" height="220">
						<br>
					</div>
					<br>
				</div>
				<div class="d-flex justify-content-center form_container" style="margin-top: 10%;">
					<form (ngSubmit)="f.form.valid && login()" #f="ngForm">
						<div class="input-group mb-3">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-user"></i></span>
							</div>
							<input type="text" name="user" [(ngModel)]="send.usuario" class="form-control input_user" value="" placeholder="usuario" required>
						</div>
						<div class="input-group mb-2">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-key"></i></span>
							</div>
							<input type="password" name="password" [(ngModel)]="send.password" class="form-control input_pass" value="" placeholder="contraseña" required>
						</div>
						<div class="form-group">
							<div class="custom-control custom-checkbox">
								<input type="checkbox" class="custom-control-input" id="customControlInline">
								<label class="custom-control-label" for="customControlInline">Recordarme</label>
							</div>
						</div>
							<div class="d-flex justify-content-center mt-3 login_container">
				 	<button type="submit" name="button" class="btn btn-primary">Iniciar Sesión</button>
				   </div>
					</form>

        </div>
        <div class="container">
				<br>
        <span style="color: red;">{{mensaje}}</span>
      </div>
				<div class="mt-4">
					<div class="d-flex justify-content-center links">
						¿No tienes una cuenta? <a href="#" class="ml-2">Registrate</a>
					</div>
					<div class="d-flex justify-content-center links">
						<a href="#">¿Olvidaste tu contraseña?</a>
					</div>
				</div>
			</div>
		</div>
	</div>

