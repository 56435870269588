
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" href="#">Imagitec</a>

  <div class="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo03">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item active">
        <a class="nav-link" href="#">Inicio</a>
      </li>
      <li class="nav-item">
          <a class="nav-link" href="#!/contacto">Contacto</a>
        </li>
        <li class="nav-item" ng-controller="navigation">
          <a class="nav-link"  ng-click="cerrarSesion()">Cerrar Sesion</a>
        </li>
    </ul>
  </div>
</nav>
