import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable()
export class LoginService{
  userLog = false;
  constructor(private httpClient: HttpClient, private router: Router){}

  iniciaSesion(credencials: object){
    return this.httpClient.post<any>('https://imagitec.mx/api/v1/usuario/login', credencials).subscribe( data => {
      if(data.status === 200){
        this.router.navigate(['perfil']);
        console.log('Redirect From login function login()');
      }else{
        /* this.mensaje = data.mensaje; */
        console.log('No Login');
      }
      } );

  }

  checkSesion(){
    return this.httpClient.get<any>('https://imagitec.mx/api/v1/usuario/isLoggedIn').subscribe(data => {
      console.log('Entro en verificacion');
      if (data.status === 200){
        this.userLog = true;
        this.router.navigate(['perfil']);
      }else{
        this.userLog = false;
      }
    });

  }
  logOut(){
    return this.httpClient.get<any>('https://imagitec.mx/api/v1/usuario/cerrarSesion').subscribe(data => {
        if (data.status === 200){
        this.userLog = false;
        this.router.navigate(['login']);
        }
    });

  }

  isAutenticado(){
    console.log('Entro en isAuth');
    return  this.userLog;
}


}

