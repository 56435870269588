import { LoginService } from './../../login/login.services';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  constructor(private loginService: LoginService, private router: Router) { }

  ngOnInit(): void {
    let isLog = this.loginService.isAutenticado();
    if ( isLog === false){
      this.router.navigate(['login']);
    }
  }
cerrarSesion(){
  this.loginService.logOut();
}
}
