import { LoginService } from './login.services';
import { LogingUser } from './usuario.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  send = {
    usuario: null,
    password: null
  };
  mensaje: string = null;

  constructor(private loginService: LoginService, private router: Router) {
   }

  ngOnInit(): void {
    this.loginService.checkSesion();
  }
  login() {
    this.loginService.iniciaSesion(this.send);
  }




}
