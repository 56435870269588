import { LoginService } from './login.services';


import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class LoginGuardian implements CanActivate {
  isLogueado: object;
  constructor(private loginService: LoginService, private router: Router){}
  canActivate ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {

      if (this.loginService.isAutenticado){
        return true;
      }else{
        this.router.navigate(['login']);
        return false;
      }
  }
}
