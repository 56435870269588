<!DOCTYPE html>
<html>
 <head>
<title>Imagitec</title>
<meta charset="UTF-8">
<base href="/">
<meta name="viewport" content="width=device-width, user-scalable=no">
<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" integrity="sha384-gfdkjb5BdAXd+lj+gudLWI+BXq4IuLW5IT+brZEZsLFm++aCMlF1V92rMkPaX4PP" crossorigin="anonymous">



</head>

<body>


<router-outlet></router-outlet>



</body>
</html>
